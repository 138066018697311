$max-width : 1450px;
$gradient-green : #63D471;
$small_devices: 992px;

.gradient-bg-top {
  background: linear-gradient(180deg, #263650 0, #05070A 50%);
}

.deep-blue-bg {
  background-color: #192231
}

.gradient-bg {
  background: linear-gradient(190.81deg, #000509 -5.39%, #041F39 27.61%, $gradient-green 90.64%);
  position: relative;
  margin-bottom: 200px;

  @media screen and (max-width: $small_devices) {
    margin-bottom: 20px;
  }

  .main_content {
    display: flex;
    flex-direction: column;
    align-content: center;
    max-width: $max-width;
    margin: auto;
  }

  .banner_strip {
    background-color: black;
    text-align: center;
    padding: 10px 0;

    a {
      text-decoration: none;
      color: #00bcff;
    }

    span {
      color: white
    }
  }

  .navbar {
    padding: 20px 5rem;
    position: sticky;
    top: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.navbar_links {
  a {
    text-decoration: none;
    color: white;

    &:hover {
      color: #1ae0bb;
    }
  }
}

.round_btn.btn {
  border-radius: 100px;
}

.btn {
  transform: scale(1);
  padding: 10px;

  &:hover {
    transform: scale(1.025);
  }

  span {
    padding: 15px;
  }
}

.cyan_btn.btn {
  background-color: #14b597;
  border-color: #14b597;

  &:hover {
    background-color: #1ae0bb;
    border-color: #1ae0bb;
  }

}

.blue_btn.btn {
  background-color: #12a5d9;
  border-color: #12a5d9;

  &:hover {
    background-color: #6fd9ff;
    border-color: #6fd9ff;
  }
}

.banner_btns {
  margin-top: 10px;

  @media screen and (max-width: $small_devices) {
    width: 100%;
    margin-left: 0 !important;
  }
}

.footer_address {
  @media screen and (max-width: $small_devices) {
    text-align: center;
  }
}

.ml-15 {
  margin-left: 15px;
}

.banner_hero {
  color: white;

  h1 {
    margin-bottom: 25px;
    font-weight: bolder;
    font-size: 50px;
    line-height: 60px;

    @media screen and (max-width: $small_devices) {
      font-size: 35px;
    }
  }

  p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
    margin-bottom: 60px;

    @media screen and (max-width: $small_devices) {
      margin: 10px !important;
    }
  }
}

.homebanner_mobile {
  display: none;

  @media screen and (max-width: $small_devices) {
    display: block;
  }
}

.banner_wrapper_col {

  display: block;

  @media screen and (max-width: $small_devices) {
    display: none;
  }
}

.banner_wrapper {
  padding: 125px 65px;
  margin-bottom: 175px;

  @media screen and (max-width: $small_devices) {
    margin-bottom: 20px;
    padding-bottom: 20px;

  }
}

.advantage_portal_banner_wrapper {
  margin-bottom: 320px;

  @media screen and (max-width: $small_devices) {
    margin-bottom: 0px;
  }
}

.features_box_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  color: white;
  margin: auto;
  position: absolute;
  bottom: -130px;
  width: 100%;

  @media screen and (max-width: $small_devices) {
    flex-direction: column;
    align-items: center;
    position: unset;
  }

  .box {
    background: #04203b;
    padding: 50px 28px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 300px;

    img {
      width: 70px;
    }

    .title {
      font-size: 20px;
      line-height: 30px;
      font-weight: bold;
    }

    .sub_title {
      color: #fff;
      font-size: 18px;
      line-height: 27px;
    }
  }
}

.advantages_box_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin: auto;
  width: 100%;

  @media screen and (max-width: $small_devices) {
    flex-direction: column;
    align-items: center;
  }

  .box {
    padding: 25px;
    display: flex;
    flex-direction: column;
    color: #fff;
    max-width: 300px;
    border: 1px solid black;
    border-radius: 20px;

    .title {
      font-size: 20px;
      line-height: 30px;
      font-weight: bold;
    }

    .sub_title {
      font-size: 18px;
      line-height: 27px;
    }
  }
}

.case_studies_ribbon {

  margin: auto;
  width: fit-content;
  max-width: $max-width;
  gap: 10px;

  img {
    width: 125px;
    margin-right: 8px;

    @media screen and (max-width: $small_devices) {
      width: 65px;
    }
  }
}

.read_case_studies {
  text-align: center;
  margin: 40px 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  transition: all .2s ease;

  a {
    text-decoration: none;
    color: #14b597;
  }
}

.footer_wrapper {
  background: #23344e;
  padding: 40px 5%;
  color: white;
  margin: 0;

  .social_links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 20px;

    svg {
      margin: 10px
    }
  }

  h3 {
    font-weight: bold;
  }

  p {
    margin: 0
  }

}

@media (max-width: 992px) {
  .navbar {
    display: none;
  }

  .navbar_mobile {
    padding: 0rem 2rem;
    margin: 0px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    height: 3.5rem;

    .hamburger_btn {
      float: right;
      height: 2rem;
      width: 2rem;
      fill: #fff;
      cursor: pointer;
    }
  }
}


@media (min-width: 992px) {
  .navbar {
    display: flex;
  }

  .navbar_mobile,
  .menu_mobile {
    display: none;
  }
}

.menu_mobile {
  border-top: 2px solid #23344e;
  padding: 0rem 2rem;
  background-color: #212529;

  ul {
    list-style-type: none;
    padding: 0px;
  }

  .menu_mobile_item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      fill: #fff;
      cursor: pointer;
    }

    a {
      color: #fff;
      font-size: 16px;
      padding: 0.75rem 0rem 0.75rem;
      text-decoration: none;
      font-weight: 500;
    }
  }

  ul.submenu {
    list-style-type: none;
    padding-left: 2rem;

    li a {
      color: #fff;
      font-size: 16px;
      padding: 0.75rem 0rem 0.75rem;
      text-decoration: none;
      line-height: 16px;
      font-weight: 500;
      display: block;
    }
  }

  button.hamburger_btn {
    width: 100%;
    margin-left: 0px;
  }

  #menuSearchBox {
    margin-top: 2rem;
    background-color: #192231;
    border-right: none;
    border-top: none;
    border-left: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    border-radius: 0%;
  }
}

.navbar_mobile_img {
  max-width: 111px;
  height: 24px;
}

.add-bg-color {
  background-color: #000;
  opacity: 1;
  z-index: 999;
}

.form-label {
  span {
    color: red
  }
}


.Zeffect {
  transform: scale(1);
  transition: transform .5s ease;

  &:hover {
    transform: scale(1.025);
    transition: transform .5s ease;
  }
}

.m-0 {
  margin: 0 !important;
}

.company_info_wrapper {
  margin: 0 20%;
  text-align: justify;

  @media screen and (max-width: $small_devices) {
    margin: 0;
  }
}