body {
  margin: 0;
  font-family: dm sans, Helvetica, Arial, Lucida, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: dm sans;
  src: url(./fonts/DMSans-Regular.ttf);
}